import React from 'react'
import BottomCarousel from './BottomCarousel'
import RaspuCountdownTimer from './RaspuCountdownTimer'
import { calculateTimeLeft } from '../utils/getTimerValues'
import { TimeToEnd } from '../types'
import Footer from './Footer'

const setAppHeight = () => document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
window.addEventListener('resize', setAppHeight)
setAppHeight()

const NewRaspuPage: React.FC = () => {
  const [timeLeft, setTimeLeft] = React.useState<TimeToEnd>(calculateTimeLeft());

  React.useEffect(() => {
    if (!timeLeft.isTimeUp) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [timeLeft]);

  
  return (
    <div className='background' style={{ backgroundImage: 'url(/RF-BG.jpg)' }}>
    <div className='container-wrapper'>
      <a href='/'>
        <img className='raspu-13-header' src="/r13-header.svg" alt="Rasputin 13" />
      </a>
      <img className='stripes' src="/stripes.svg" alt="stripes" />
      <div className='paper'>
        {timeLeft.isTimeUp ?
          null
          :
          <div className='center countdown-container'>
            <RaspuCountdownTimer timeLeft={timeLeft}/>
          </div>
        }
      </div>

      <BottomCarousel />
    </div>
    <Footer />
  </div>
  )
}

export default NewRaspuPage